import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ProductItem from "../../../CommonBlocks/ProductItem/ProductItem";
import { Link, withTranslation } from "../../../../i18n";
import { productSliderSettings, slideHeightFix } from "../../../HelperComponents/functions";

// import "../PopularProduct/PopularProduct.scss";
import "../VisitedProduct/VisitedProduct.scss";

import { sendRequest } from "../../../HelperComponents/functions";
import { API_BASE_URL } from "../../../../context/appState";

const pageSize = 10;
let sliderSize = 5;

const SliderTypeHomeBlock = ({ title, initialProducts, t, link }) => {
    const [loading, setLoading] = useState(true);
    const [products, setProds] = useState(initialProducts);

    const additionalSettings = {
        afterChange: e => {
            if (e + sliderSize >= products.results.length && products.results.length !== products.count) {
                sliderChange(((e + sliderSize) / pageSize) + 1);
            } else {
                slideHeightFix();
            }
        }
    }

    const sliderChange = async (page) => {
        await sendRequest(`${API_BASE_URL}/products/${link}/?page_size=${pageSize}&page=${page}`, "get").then(res => {
            if (res.status === 200) {
                res.json().then(data => {
                    setProds({ ...data, results: [...products.results, ...data.results] });
                }).then(() => {
                    slideHeightFix();
                })
            }
        })
    };

    useEffect(() => {
        products && setLoading(false);
        window.addEventListener('resize', () => {
            let breakpoint = productSliderSettings.responsive.reverse().find(el => el.breakpoint >= window.innerWidth),
                tempSize = breakpoint ? breakpoint.settings.slidesToShow : 5;
            if (tempSize !== sliderSize) sliderSize = tempSize;
        })
    }, []);

    useEffect(() => {
        slideHeightFix();
    }, [loading]);

    if (!products) return null;

    return (
        !loading && (
            <div className="visited_product with_slider">
                <p className="title">{t(title)}</p>
                <div className="products_slider">
                    {products.results.length <= 4 ? (
                        <div className="no_slider">
                            {products.results.map((el, idx) => (
                                <ProductItem key={idx} productItem={el} index={idx} isVisited />
                            ))}
                        </div>
                    ) : (
                        <Slider {...{ ...productSliderSettings, ...additionalSettings }}>
                            {products.results.map((el, idx) => (
                                <div key={idx}>
                                    <ProductItem productItem={el} index={idx} moreThanFour isVisited />
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>

                {title === 'Акции' ?
                    <Link href="/actions" >
                        <a rel="nofollow" className="show_more">
                            {t("смотреть ещё")}
                        </a>
                    </Link>
                    : null
                }
            </div>
        )
    );
};

export default withTranslation("home")(SliderTypeHomeBlock);
