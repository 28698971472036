import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Router, withTranslation } from "../../../i18n";

import "./BrandsSlider.scss";

class BrandsSlider extends Component {
    state = {
        play: true
    };
    render() {
        const { elements, amountSlides } = this.props;
        const settings = {
            dots: false,
            infinite: true,
            useTransform: true,
            speed: 4500,
            slidesToShow: amountSlides,
            slidesToScroll: 1,
            arrows: false,
            cssEase: "linear",
            autoplaySpeed: 0,
            autoplay: this.state.play,
            pauseOnHover: false,
            centerMode: false,
            draggable: false
        };
        return (
            <div className="tech_slider_wrapper">
                <Slider {...settings} ref={slider => (this.slider = slider)}>
                    {elements.map((el, idx) => (
                        <div className="item" key={idx}>
                            <a
                                onClick={() => {
                                    Router.push(`/search/${el.name}`);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div>
                                    <img src={el.image} alt={`${el.name} PRORAB`} title={`${el.name} внешний вид`} />
                                </div>
                                <span>{el.name}</span>
                            </a>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}

export default withTranslation("home")(BrandsSlider);
