import React, { useState } from "react";
import "./PopularCategories.scss";

import { withTranslation, Link } from "../../../../i18n";

const PopularCategories = ({ popularCategories, t, i18n: { language } }) => {
    const [state, setState] = useState({
        show_more: false
    });
    if (popularCategories.length === 0) return null;
    return (
        <div className="popular_categories home_page_product">
            <p className="title">{t("Популярные категории")}</p>
            <div className={`category_block ${state.show_more ? "opened" : "less"}`}>
                {popularCategories.map(
                    (el, idx) =>
                        (idx <= 5 && (
                            <div key={idx}>
                                <Link
                                    key={idx}
                                    href/* ="/categories/[...slug]"
                                    as */={`/categories/${el.relative_url}`}
                                    prefetch={false}
                                >
                                    <a>
                                        <div>
                                            <img
                                                src={el.image}
                                                alt={`${el.name} PRORAB`}
                                                title={`${el.name} внешний вид`}
                                            />
                                        </div>
                                        <span>{language === "ua" ? el.name_ua : el.name}</span>
                                    </a>
                                </Link>
                            </div>
                        )) ||
                        (idx > 5 && state.show_more && (
                            <div key={idx}>
                                <Link
                                    key={idx}
                                    href/* ="/categories/[...slug]"
                                    as */={`/categories/${el.relative_url}`}
                                    prefetch={false}
                                >
                                    <a>
                                        <div>
                                            <img
                                                src={el.image}
                                                alt={`${el.name} PRORAB`}
                                                title={`${el.name} внешний вид`}
                                            />
                                        </div>
                                        <span>{language === "ua" ? el.name_ua : el.name}</span>
                                    </a>
                                </Link>
                            </div>
                        ))
                )}
            </div>
            {popularCategories && popularCategories.length > 5 && (
                <button
                    onClick={() => setState({ show_more: true })}
                    className={`show_more ${state.show_more ? "invisible" : null}`}
                >
                    {t("смотреть ещё")}
                </button>
            )}
        </div>
    );
};

export default withTranslation("home")(PopularCategories);
