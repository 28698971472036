import React from "react";
import "./ProductBrands.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Router, withTranslation } from "../../../../i18n";
import BrandsSlider from "./../../../HelperComponents/BrandsSlider/BrandsSlider";
import { useWindowSize } from "./../../../../helpers/changeScreen";

const ProductBrands = ({ ourBrands, t }) => {
    const mediaWidth = useWindowSize();

    if (ourBrands.length === 0) return null;
    return (
        <div className="product_brands with_slider">
            <p className="title">{t("Наши бренды")}</p>
            <div className="products_slider">
                {(ourBrands && ourBrands.length <= 6) || mediaWidth < 1350
                    ? ourBrands.map((el, idx) => (
                          <div className="item" key={idx}>
                              <a
                                  onClick={() => {
                                      Router.push(`/search/${el.name}`);
                                      window.scrollTo(0, 0);
                                  }}
                              >
                                  <div>
                                      <img src={el.image} alt={`${el.name} PRORAB`} title={`${el.name} внешний вид`} />
                                  </div>
                                  <span>{el.name}</span>
                              </a>
                          </div>
                      ))
                    : null}
                {ourBrands && ourBrands.length > 6 && mediaWidth > 1350 ? (
                    <BrandsSlider elements={ourBrands} amountSlides={6} />
                ) : null}
            </div>
        </div>
    );
};

export default withTranslation("home")(ProductBrands);
