import React, { useContext, useEffect } from "react";
import "./ProductReview.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import Rating from "../../../HelperComponents/Rating/Rating";
import add_cart from "../../../../public/images/add_cart.svg";
import { AppContext } from "../../../../context/appState";
import { flyingItem } from "../../../HelperComponents/functions";

import { withTranslation, Link } from "../../../../i18n";
import RatingPrecision from "../../../HelperComponents/RatingPrecision/RatingPrecision";

const ProductReview = ({ productsReview, t, i18n: { language } }) => {
    const { dispatchCart, dispatch } = useContext(AppContext);
    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1250,
                settings: {
                    dots: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    useEffect(() => {
        moment.locale(language === "ua" ? "uk" : "ru");
    }, [language]);

    const addToCart = (item, quantity) => {
        let localBasket =
            JSON.parse(localStorage.getItem("cart")) !== null ? JSON.parse(localStorage.getItem("cart")) : [],
            gconfig = {
                send_to: "AW-10994701989",
                items: [
                    {
                        quantity,
                        id: item.article_number,
                        name: item.name_ua,
                        category: item.category_tree
                            .slice(-5)
                            .map(el => el.name_ua)
                            .join("/"),
                        price: item.discount_price ? item.discount_price : item.price,
                        google_business_vertical: "retail"
                    }
                ]
            };

        fbq("track", "AddToCart", {
            value: item.discount_price ? item.discount_price : item.price, //передавать цену товара
            currency: "UAH",
            content_ids: item.article_number, //передавать ИД товара
            content_type: "product"
        });

        gtag("event", "add_to_cart", gconfig);

        if (localBasket.length > 0) {
            if (localBasket.some(el => el.id === item.id)) {
                for (let i = 0; i < localBasket.length; i++) {
                    if (item.id === localBasket[i].id) {
                        localBasket[i].quantity += quantity;
                        localBasket[i].price = (
                            localBasket[i].quantity * (item.discount_price ? item.discount_price : item.price)
                        ).toFixed(2);
                        break;
                    }
                }
            } else {
                localBasket.unshift({
                    id: item.id,
                    quantity: quantity,
                    price: quantity * (item.discount_price ? item.discount_price : item.price),
                    item: item
                });
            }
        } else {
            localBasket.unshift({
                id: item.id,
                quantity: quantity,
                price: quantity * (item.discount_price ? item.discount_price : item.price),
                item: item
            });
        }
        dispatchCart(localBasket, dispatch);
        localStorage.setItem("cart", JSON.stringify(localBasket));
    };
    if (productsReview.length === 0) return null;
    return (
        <div className="product_review with_slider">
            <p className="title">{t("Отзывы покупателей")}</p>
            <div className="products_slider">
                <Slider {...settings}>
                    {productsReview.map((el, idx) => (
                        <div className="review_wrapper block_for_drag" key={idx}>
                            <section className="inner_product">
                                <img
                                    src={el.product.images[0]}
                                    alt={`${el.product.name} PRORAB`}
                                    title={`${el.product.name} внешний вид`}
                                    className={`img_to_drag`}
                                />
                                <div className="info_product">
                                    <div className="info_top">
                                        <Link href/* ={`/product/[...slug]`} as */={`/product/${el.product.slug}`}>
                                            <a>{language === "ua" ? el.product.name_ua : el.product.name}</a>
                                        </Link>
                                        {el.product.popularity ? (
                                            <span className="red">{t("популярный")}</span>
                                        ) : el.product.new ? (
                                            <span className="green">{t("новинка")}</span>
                                        ) : null}
                                    </div>
                                    <div className="info_mid">
                                        <RatingPrecision size={14} value={el?.product?.rating || 0} />
                                        {/* <Rating
                                            size={14}
                                            edit={false}
                                            value={el.product.rating && el.product.rating.toFixed()}
                                        /> */}
                                        {/*{el.product.get_availability === "В наличии" ? (*/}
                                        {/*    <span>{t("В наличии")}</span>*/}
                                        {/*) : (*/}
                                        {/*    <span style={el.product.get_availability === "В наличии" ? null : null}>*/}
                                        {/*        {el.product.availability === 4 ? t("Заканчивается") : t("Предзаказ")}*/}
                                        {/*    </span>*/}
                                        {/*)}*/}

                                        <span>{language === "ua" ? el.product.get_availability_ua : el.product.get_availability}</span>
                                    </div>
                                    <div className="info_bot">
                                        {el.product.discount_price ? (
                                            <div className="with_discount">
                                                <s>{el.product.price} грн</s>
                                                <div style={{ color: "#FF010B" }}>
                                                    {el.product.discount_price.toFixed(2)} грн
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div>{el.product.price.toFixed(2)} грн</div>
                                            </div>
                                        )}
                                        <div className="info_btn_box">
                                            <button
                                                disabled={
                                                    !el.product.availability === 4 && !el.product.availability === 1
                                                }
                                                onClick={e => {
                                                    addToCart(el.product, 1);
                                                    flyingItem(e);
                                                }}
                                            >
                                                <img
                                                    src={add_cart}
                                                    alt="добавить в корзину image"
                                                    title="PRORAB добавить в корзину"
                                                />
                                                {t("Купить")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="inner_review">
                                <span className="title">{el.title}</span>
                                <Rating size={14} edit={false} value={el.rating} />
                                <p>{el.message}</p>
                                <span className="username">{el.username}</span>
                                <span>{moment(el.date).format("LLL")}</span>
                            </section>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default withTranslation("product")(ProductReview);
