import React, { useContext } from "react";
import { withTranslation, Link } from "../../../i18n";
import "./SeoTextMainPage.scss";

const SeoTextMainPage = ({jsonMainpage, i18n: { language }}) => {
    return (
        <>
            {language === "ua" ?
                <div>
                    {jsonMainpage.seo_text_ua === '' ?
                        null:
                        <div className="seo_text_main_page"
                             dangerouslySetInnerHTML={{
                                 __html: `${jsonMainpage.seo_text_ua}`
                             }}
                        />
                    }
                </div>
                :
                <div>
                    {jsonMainpage.seo_text === '' ?
                        null:
                        <div className="seo_text_main_page"
                             dangerouslySetInnerHTML={{
                                 __html: `${jsonMainpage.seo_text}`
                             }}
                        />
                    }
                </div>
            }
        </>
    );
};
export default withTranslation("product")(SeoTextMainPage);


