import React, { useEffect, useState, useContext } from "react";

//import PopularProduct from "../components/PageBlocks/Home/PopularProduct/PopularProduct";
import PopularCategories from "../components/PageBlocks/Home/PopularCategories/PopularCategories";
import SliderBlock from "../components/PageBlocks/Home/SliderBlock/SliderBlock";
import VisitedProduct from "../components/PageBlocks/Home/VisitedProduct/VisitedProduct";
import ProductBrands from "../components/PageBlocks/Home/ProductBrands/ProductBrands";
import ProductReview from "../components/PageBlocks/Home/ProductReview/ProductReview";
import SeoTextMainPage from "../components/PageBlocks/SeoTextMainPage/SeoTextMainPage";
import { API_BASE_URL } from "../context/appState";
import Head from "next/head";
/* import SaleProduct from "../components/PageBlocks/Home/SaleProduct/SaleProduct";
import TopProduct from "../components/PageBlocks/Home/TopProduct/TopProduct"; */
//import CustomBlock from "../components/PageBlocks/Home/CustomBlock/CustomBlock";
import SliderTypeHomeBlock from "../components/PageBlocks/Home/SliderTypeHomeBlock/SliderTypeHomeBlock";
import { AppContext } from "../context/appState";
//import { i18n } from "../i18n";
import ProductsWithTitle from "../components/PageBlocks/ProductPage/ProductsWithTitle";

const Home = props => {
    //const [timer, setTimer] = useState(true);
    const { setLoader, loader, dispatch } = useContext(AppContext);
    //const { language } = i18n;
    const { isUA } = props

    useEffect(() => {
        setLoader(true, dispatch);
        /* setTimeout(() => {
            setTimer(false);
        }, 250); */
    }, []);

    return (
        <div className="home_page">
            <Head>
                <script type="application/ld+json">{JSON.stringify(props.schema)}</script>
            </Head>
            <SliderBlock {...{ jsonMainpage: props.mainpage, ...props }} />
            <PopularCategories {...props} />
            <ProductsWithTitle list={props.newProducts} title={'Новинки'} />
            <ProductsWithTitle list={props.customBlock.products} title={isUA ? props.customBlock.title_ua : props.customBlock.title} WithoutT />
            {/* <CustomBlock {...props} /> */}
            {/* <SliderTypeHomeBlock {...{initialProducts: props.saleProducts, title: 'Акции', t: props.t, link: 'promotional'}} /> */}
            {!!props.topSales.count && <SliderTypeHomeBlock {...{ initialProducts: props.topSales, title: 'Топ продаж', t: props.t, link: 'top_sales' }} />}
            {/*<SaleProduct {...props} />*/}
            {/* <TopProduct {...props} /> */}
            {/*<PopularProduct {...props} />*/}
            <SeoTextMainPage {...{ jsonMainpage: props.mainpage, ...props }} />
            <VisitedProduct />
            <ProductBrands {...props} />
            <ProductReview {...props} />
        </div>
    );
};

Home.getInitialProps = async function (context) {
    const [
        //json,
        jsonNew,
        jsonCats,
        //jsonSale,
        jsonBrand,
        jsonReview,
        respSlider,
        jsonTopSales,
        customBlock,
        jsonMainpage,
    ] = await Promise.all([
        //fetch(`${API_BASE_URL}/products/popularity/?page_size=20`).then(res => res.json()),
        fetch(`${API_BASE_URL}/products/new/?page_size=20`).then(res => res.json()),
        fetch(`${API_BASE_URL}/category/popularity/?page_size=20`).then(res => res.json()),
        //fetch(`${API_BASE_URL}/products/promotional/?page_size=10`).then(res => res.json()),
        fetch(`${API_BASE_URL}/brand/`).then(res => res.json()),
        fetch(`${API_BASE_URL}/reviews/display-on-main/`).then(res => res.json()),
        fetch(`${API_BASE_URL}/slider/`).then(res => res.json()),
        fetch(`${API_BASE_URL}/products/top_sales/?page_size=10`).then(res => res.json()),
        fetch(`${API_BASE_URL}/products/block/`).then(res => res.json()),
        fetch(`${API_BASE_URL}/mainpage/`).then(res => res.json())
    ]);
    const isUA = context?.req?.i18n?.language && context?.req?.i18n?.language === "ua";

    let schema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        address: {
            "@type": "PostalAddress",
            addressLocality: "Украина, Полтава",
            postalCode: "36000",
            streetAddress: "проспект Мира 36"
        },
        email: "poltava.prorab@gmail.com",
        name: "PRORAB: Маркет для дома",
        telephone: "0 800 33 07 57"
    };
    return {
        //popularProducts: json,
        newProducts: jsonNew,
        mainpage: jsonMainpage,
        //saleProducts: jsonSale,
        popularCategories: jsonCats,
        ourBrands: jsonBrand,
        productsReview: jsonReview,
        sliderImages: respSlider,
        topSales: jsonTopSales,

        title: "Строительный магазин - интернет магазин бытовой техники, заказать товары для дома в интернет магазине Prorab.group",
        description:
            "Заказывайте строительные материалы с доставкой на дом в реальном магазине! 5 лет опыта✓10000 товаров ✓100 довольных клиентов в день ✓Поможем с выбором ✓ Бесплатно доставим! Заходите ☛☛☛",
        keywords: "Строительный материалы, интернет магазин строительных материалов",
        pageClasses: "relative_wrapper",
        schema: schema,
        namespacesRequired: ["home", "product"],
        customBlock,
        isUA
    };
};

export default Home;
